import React, { useEffect, useState } from "react";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import GiftAPI from "../../utils/API/GiftAPI";
import useUserStore from "../../stores/userStore";



function Preferiti() {
    /**
     * @type {[import("../../utils/API/GiftAPI").Gift[], function]}
     */
    const user = useUserStore((state) => state.user);
    const userId = user.uid;
    const [preferiti, setPreferiti] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const fetchLiked = async () => {
        if (!userId) {
            setFavourites([]);
        }

        const favouriteGifts = await GiftAPI.getFavourites();;

        setFavourites(favouriteGifts);

        setLoaded(true);
    };

    useEffect(() => {
        fetchLiked();
    }, []);

    return (
        <div className="grid min-h-[80vh] mt-5 xl:grid-cols-5 lg:grid-cols-4  md:grid-cols-3 grid-cols-2 md:gap-5 gap-3">
            {loaded ? (
                favourites.length ? (
                    favourites.map((item, i) => {
                        return (
                            <div className="" key={i}>
                                <CardPublic
                                    user={user}
                                    gift={item}
                                    
                                    key={i}
                                />
                            </div>
                        );
                    })
                ) : (
                    <div className="flex w-full col-span-full justify-center items-start md:items-center mt-20 text-center h-96 text-xl ">
                        <p>
                            Non hai ancora aggiunto nessun regalo ai preferiti
                        </p>
                    </div>
                )
            ) : (
                <Caricamento />
            )}
        </div>
    );
}

export default Preferiti;
