import { apiGet, apiPost, apiPut, objectKeyFromS3Url, uploadImage } from "./CallBackend";

/**
 * @typedef {Object} Gift
 * @property {string} _id
 * @property {string} owner
 * @property {string} name
 * @property {string} description
 * @property {GiftLocation} location
 * @property {string[]} images
 * @property {number} price
 * @property {string} category
 * @property {string} availability
 * @property {string} status
 * @property {boolean} isPublic
 * @property {boolean} isAssigned
 * @property {boolean} isAssignedToMe
 * @property {boolean} isBookedByMe
 * @property {number} bookingsLength
 * @property {boolean} isDelivered
 * @property {Date} createdAt
 */

/**
 * @typedef {Object} GiftPrivateFields
 * @property {string[]} bookings
 * @property {string} assignedTo
 */

/**
 * @typedef {Gift & GiftPrivateFields} OwnerGift
 */

export const giftStatus = {
    "new": "Come nuovo",
    "good": "Buone",
    "acceptable": "Accettabile",
    "to_fix": "Da riparare"
};

export const giftAvailability = {
    "weekend": "Fine settimana",
    "workdays_evening": "Infrasettimanale di sera",
    "workdays_day": "Infrasettimanale di giorno",
    "flexible": "Flessibile"
};

const apiPrefix = "gift";

const GiftAPI = {
    /**
     * @param {string} [searchTerm]
     * @returns {Promise<Gift[]>}
     */
    getPublic: async (searchTerm) => {
        if (searchTerm) {
            return await apiGet(`${apiPrefix}?search=${searchTerm}`, true);
        }

        return await apiGet(`${apiPrefix}`, true);
    },

    /**
     * @param {string} giftId 
     * @returns {Promise<Gift>}
     */
    getById: async (giftId) => {
        return await apiGet(`${apiPrefix}/${giftId}`, true);
    },

    /**
     * @param {string} owner
     * @returns {Promise<Gift[]>}
     */
    getByOwner: async (owner) => {
        const gifts = await apiGet(`${apiPrefix}?owner=${owner}`, true);

        if (gifts) {
            return gifts;
        }
        return [];
    },

    /**
     * @returns {Promise<Gift[]>}
     */
    getBooked: async () => {
        const gifts = await apiGet(`${apiPrefix}/gift/booked`, true);

        if (gifts) {
            return gifts;
        }
        return [];
    },

    /**
     * @returns {Promise<Gift[]>}
     */
    getFavourites: async () => {
        const favourites = await apiGet(`${apiPrefix}/gift/favourites`, true);
        if (favourites) {
            return favourites;
        }
        return [];
    },

    /**
     * @param {Object} gift
     * @returns {Promise<string>} The id of the new gift
     */
    addGift: async (gift) => {
        const giftId = await apiPost(`${apiPrefix}`, {
            gift: {
                ...gift,
                availability: 'weekend',
                status: 'new',
                location: {
                    city: 'Olbia',
                    country: 'Italia',
                    address: 'Viale Aldo Moro 8',
                    lat: 40,
                    lng: 40
                }
            }
        }, true);
        return giftId;
    },

    /**
     * 
     * @param {string} giftId 
     * @param {Object} gift 
     */
    updateGift: async (giftId, gift) => {
        await apiPut(`${apiPrefix}/${giftId}`, {
            gift: gift
        }, true);
    },

    /**
     * 
     * @param {string} giftId 
     * @param {*} imageFile 
     * @returns {Promise<string>}
     */
    uploadImage: async (giftId, imageFile) => {
        const signedUrl = await apiPost(`${apiPrefix}/${giftId}/image`, {}, true);
        await uploadImage(imageFile, signedUrl);
        return objectKeyFromS3Url(signedUrl);
    }
};

export default GiftAPI;