import { auth } from "../../firebase";
import { apiDelete, apiGet, apiPost, apiPut, objectKeyFromS3Url, uploadImage } from "./CallBackend";

const apiPrefix = 'user';

/**
 * @typedef {Object} User
 * @property {string} _id
 * @property {string} uid
 * @property {string} email
 * @property {string} name
 * @property {string} surname
 * @property {GiftLocation|null} location
 * @property {number} points
 * @property {number} level
 * @property {string} image
 * @property {number} donations
 * @property {number} adoptions
 * @property {boolean} isFirstLogin
 * @property {boolean} hasNotifications
 * @property {string|null} invitedBy 
 * @property {Date} createdAt
 */

/**
 * @typedef {Object} Review
 * @property {string} _id
 * @property {string} toUser
 * @property {string} author
 * @property {string} content
 * @property {number} rating
 * @property {Date} createdAt
 */


const UserAPI = {
    /**
     * @param {string} userId 
     * @returns {Promise<User>}
     */
    getById: async (userId) => {
        return await apiGet(`${apiPrefix}/${userId}`);
    },

    /**
     * @param {string} userId 
     * @returns {Promise<Review[]>}
     */
    getReviews: async (userId) => {
        return await apiGet(`${apiPrefix}/${userId}/reviews`);
    },

    /**
     * @param {string} userId
     * @param {Review} review
     */
    addReview: async (userId, review) => {
        await apiPost(`${apiPrefix}/${userId}/reviews`, {
            review: review
        }, true);
    },

    /**
     * @returns {Promise<string[]>}
     */
    getFavourites: async () => {
        const favourites = await apiGet(`${apiPrefix}/user/favourites`, true);

        if (favourites) {
            return favourites;
        }
        return [];
    },

    /**
     * @param {string} giftId 
     */
    addFavourite: async (giftId) => {
        await apiPost(`${apiPrefix}/favourite/${giftId}`, {}, true);
    },

    /**
     * @param {string} giftId
     */
    removeFavourite: async (giftId) => {
        await apiDelete(`${apiPrefix}/favourite/${giftId}`, true);
    },

    updateFirstLogin: async () => {
        await apiPut(`${apiPrefix}/user/firstlogin`, {}, true);
    },

    /**
     * 
     * @param {*} imageFile 
     */
    uploadProfilePic: async (imageFile) => {
        const signedUrl = await apiPost(`${apiPrefix}/user/image`, {}, true);
        await uploadImage(imageFile, signedUrl, auth.currentUser.uid);
        await UserAPI.update({
            image: objectKeyFromS3Url(signedUrl)
        });
    },

    update: async (user) => {
        await apiPut(`${apiPrefix}`, {
            user: user
        }, true);
    },
    addUser: async (user, uid) => {
        await apiPost(`${apiPrefix}/`, {
            user: user,
        }, true);
    }
};

export default UserAPI;