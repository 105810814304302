import axios from "axios";
import { auth } from "../../firebase";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
export const websocketUrl = process.env.REACT_APP_WEBSOCKET_ENDPOINT;

const parseAPIData = (data) => {
    if (data.createdAt) {
        data.createdAt = new Date(data.createdAt);
    }
    if (data.image) {
        data.image = `${process.env.REACT_APP_IMAGES_BUCKET}/${data.image}`;
    }
    if (data.images) {
        for (let i = 0; i < data.images.length; i++) {
            if (!data.images[i] || data.images[i].startsWith('http')) {
                continue;
            }
            data.images[i] = `${process.env.REACT_APP_IMAGES_BUCKET}/${data.images[i]}`;
        }
    }
};

/**
 * 
 * @param {string} path 
 * @param {boolean} requiresAuth 
 * @returns 
 */
export const apiGet = async (path, requiresAuth = false) => {
    const headers = {};

    if (auth.currentUser && requiresAuth) {
        const idToken = await auth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${idToken}`;
    }

    const res = await axios.get(`${backendUrl}/${path}`,
        {
            headers: headers,
            withCredentials: true
        }
    );

    if (res.status === 204) {
        return null;
    }

    const data = res.data;

    if (Array.isArray(res.data)) {
        for (let d of data) {
            parseAPIData(d);
        }
    }
    else {
        parseAPIData(data);
    }

    return data;
};

/**
 * 
 * @param {string} path 
 * @param {Object} body 
 * @param {boolean} requiresAuth 
 * @returns {Promise<Object>}
 */
export const apiPost = async (path, body, requiresAuth = false) => {
    const headers = {};

    if (auth.currentUser && requiresAuth) {
        const idToken = await auth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${idToken}`;
    }

    const res = await axios.post(`${backendUrl}/${path}`, body,
        {
            headers: headers,
            withCredentials: true
        }
    );

    return res.data;
};

/**
 * 
 * @param {string} path 
 * @param {boolean} requiresAuth 
 * @returns {Promise<Object>}
 */
export const apiDelete = async (path, requiresAuth = false) => {
    const headers = {};

    if (auth.currentUser && requiresAuth) {
        const idToken = await auth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${idToken}`;
    }

    const res = await axios.delete(`${backendUrl}/${path}`,
        {
            headers: headers,
            withCredentials: true
        }
    );

    return res.data;
};

/**
 * 
 * @param {string} path 
 * @param {Object} body 
 * @param {boolean} requiresAuth 
 * @returns {Promise<Object>}
 */
export const apiPut = async (path, body, requiresAuth = false) => {
    const headers = {};

    if (auth.currentUser && requiresAuth) {
        const idToken = await auth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${idToken}`;
    }

    const res = await axios.put(`${backendUrl}/${path}`, body,
        {
            headers: headers,
            withCredentials: true
        }
    );

    return res.data;
};

export const uploadImage = async (imageFile, signedUrl) => {
    await axios.put(
        signedUrl,
        imageFile,
        {
            headers: {
                'Content-Type': 'image/jpeg',
                //'x-amz-meta-itemId': itemId
            }
        }
    );
};

export const objectKeyFromS3Url = (presignedUrl) => {
    const url = new URL(presignedUrl);
    return url.pathname.substring(1);
};