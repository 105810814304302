import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import ProfilePic from '../ProfilePic/ProfilePic'

import Recensione from '../../../assets/Icons/Recensione'
import Iconfrecciagiu from '../../../assets/Icons/Iconfrecciagiu'

import UserAPI from "../../../utils/API/UserAPI";

const RecensioniDonatore = ({ ownerId }) => {
    const [recensioni, setRecensioni] = useState([]);
    const [stars, setStars] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const handleLoad = async () => {
        const reviews = await UserAPI.getReviews(ownerId);

        const ratingAverage = (reviews.reduce((accumulator, current) => accumulator + current.rating, 0) / reviews.length).toFixed(1);

		setStars(ratingAverage);
		setRecensioni(reviews);

        setLoaded(true);
    }

    useEffect(() => {
        if (!loaded) {
            handleLoad();
        }
    }, []);

    return (
        <div className="flex items-center space-x-1">
            {!recensioni.length && (
                <div className="w-5 text-verde">
                    <Recensione />
                </div>
            )}
            <div className=" flex justify-start font-light items-center space-x-1">
                <p className="">
                    {recensioni.length ? stars : "Nessuna recensione"}
                </p>
                {Array.from({
                    length: Math.floor(stars),
                }).map((_, i) => {
                    return (
                        <div key={i} className="w-5 text-verde">
                            <Recensione />
                        </div>
                    );
                })}
                {Array.from({
                    length: 5 - Math.floor(stars),
                }).map((_, i) => {
                    return (
                        <div key={i} className="w-5 text-grigio/50">
                            <Recensione />
                        </div>
                    );
                })}
                <p>({recensioni.length})</p>
            </div>
        </div>
    )
}

/**
 * @param {Object} props
 * @param {import("../../../utils/API/UserAPI").User} props.owner
 * @returns 
 */
const InfoDonatore = ({ owner, showRecensioni = false }) => {
    return (
        <div className="bg-white px-3 pt-3 pb-2 rounded-lg">
            <div
                onClick={() =>
                    (window.location.href = "/profilo/" + owner._id)
                }
                className=" cursor-pointer pl-1 w-full flex gap-4 items-center"
            >
                <div className="scale-125 flex justify-center items-center">
                    <ProfilePic userId={owner._id} />
                </div>
                <div className="flex justify-between  items-center w-full">
                    <div className="font-medium">
                        {owner.name} {owner.surname}
                        {showRecensioni && <RecensioniDonatore ownerId={owner._id} />}
                    </div>
                    <div className="w-4 opacity-50 rotate-180">
                        <Iconfrecciagiu />
                    </div>
                </div>
            </div>
            <div className="w-full h-[2px] my-2 bg-verdeScuro/10" />
            <div className="mt-3 text-sm">
                <p className=" font-thin">
                    Membro dal:{" "}
                    <span className="font-normal">
                        <Moment format="ll" locale="it">
                            {owner.createdAt.toISOString()}
                        </Moment>
                    </span>
                </p>

                <p className=" font-thin">
                    Donazioni:{" "}
                    <span className="font-normal">{owner.donations || 0}</span>
                </p>
                <p className=" font-thin">
                    Adozioni:{" "}
                    <span className="font-normal">{owner.adoptions || 0}</span>
                </p>

                <p className=" font-thin">
                    Promesse non mantenute:{" "}
                    <span className="font-normal">0</span>
                </p>
            </div>
        </div>
    )
}

export default InfoDonatore