import React, { useEffect, useState } from "react";
import UserAPI from "../../../utils/API/UserAPI";

function ProfilePicName({ userId, key = 0, full = false, textStyle }) {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const getUser = async () => {
        try {
            setLoaded(false);
            const user = await UserAPI.getById(userId);
            setUser(user);
            setLoaded(true);
        } catch (error) {
        
        }
    };

    useEffect(() => {
        getUser();
    }, [key]);

    return (
        <>
            {loaded && user && (
                <div className="flex items-center space-x-2">
                    <p className={textStyle || "text-black md:text-sm text-xs"}>{user.name}</p>
                </div>
            )}
        </>
    );
}

export default ProfilePicName;
