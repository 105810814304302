import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import BottoneGrigio from "../Bottoni/BottoneGrigio";
import axios from "axios";

const VerificaMail = ({ user }) => {
    const mailVerified = window.sessionStorage.getItem("notVerified");
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = async (e) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
        await axios
            .post(
                process.env.REACT_APP_API_URL + "/mail/benvenuto",
                { nome: user.nome, email: user.mail },
                { headers: headers }
            )
            .then((res) => {
                console.log(res.data);
            });
        setIsOpen(false);
    };

    useEffect(() => {
        if (
            window.sessionStorage.getItem("notVerified") === "true" &&
            !user.emailVerified
        ) {
            setIsOpen(true);
            console.log(user);
        } else {
            window.sessionStorage.setItem("notVerified", false);
            window.location.reload();
        }
    }, [mailVerified, user.emailVerified]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => handleClose(false)}
                    className="bg-slate-900/20 text-xs backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  "
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden"
                    >
                        <div className="flex justify-end w-full"></div>
                        <div className="w-full flex px-4 flex-col text-center gap-5">
                            <p className="text-center text-3xl font-bold">
                                Verifica mail!
                            </p>
                            <p>
                                Controlla la tua email e verifica l'account
                                prima di proseguire. <br />
                                Se non trovi la mail, controlla anche nella
                                cartella spam.
                            </p>
                        </div>
                        <div className="flex justify-center mt-8">
                            <div className="">
                                <BottoneGrigio
                                    text={"Invia verifica"}
                                    onclick={() => handleClose(true)}
                                />
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default VerificaMail;
