import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import ProfilePic from "../../Components/Atoms/ProfilePic/ProfilePic";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import Segnala from "../../Components/Atoms/Modals/Segnala";
import Seme from "../../assets/Livelli/Livello 1 SEME.png";
import Recensione from "../../assets/Icons/Recensione";
import CardRecensioni from "../../Components/Atoms/Card/CardRecensioni";
import { Recensisci } from "../../Components/Atoms/Modals/Recensisci";
import Moment from "react-moment";
import GiftAPI from "../../utils/API/GiftAPI";
import UserAPI from "../../utils/API/UserAPI";
import useUserStore from "../../stores/userStore";

function Profili() {
    //const user = useUserStore((state) => state.user);
    const [user, setUser] = useState()
    const slug = useParams().id;
    const [loaded, setLoaded] = useState(false);
    const [loaded2, setLoaded2] = useState(false);
    /**
     * @type {[import("../../utils/API/UserAPI").User, function]}
     */
    /**
     * @type {[import("../../utils/API/GiftAPI").Gift[], function]}
     */
    const [regali, setRegali] = useState([]);
    const [recensioni, setRecensioni] = useState([]);
    const [stars, setStars] = useState(0);

    const handleLoad = async () => {
        const user = await UserAPI.getById(slug);
        setUser(user);

        const reviews = await UserAPI.getReviews(user.uid);

        const ratingAverage = (reviews.reduce((accumulator, current) => accumulator + current.rating, 0) / reviews.length).toFixed(1);

		setStars(ratingAverage);
		setRecensioni(reviews);

        setLoaded(true);
    };

    const handleLoadRegali = async () => {
        const gifts = await GiftAPI.getByOwner(slug);
        setRegali(gifts);
        setLoaded2(true);
    };

    useEffect(() => {
        handleLoad();
        handleLoadRegali();
    }, []);

    const lvl = {
        0: { regali: 0, nome: "seme", img: Seme },
        1: { regali: 1, nome: "germoglio" },
        2: { regali: 3, nome: "pianta" },
        3: { regali: 10, nome: "albero" },
        4: { regali: 20, nome: "foresta" },
        5: { regali: 30, nome: "giungla" },
    };

    return (
        <>
            {!loaded ? (
                <Caricamento />
            ) : (
                <div className="min-h-[70vh] pb-20">
                    <div className="flex md:flex-row flex-col items-start  w-full justify-start gap-10  ">
                        <div className="flex items-start mt-5 gap-5">
                            <div className="p-2 flex justify-center items-center">
                                <div
                                    className={`relative md:w-28 w-20 flex justify-center items-center`}
                                >
                                    <ProfilePic userId={user.uid} w={"full"} />
                                </div>
                            </div>
                            <div className="flex max-w-[85vw] flex-col ">
                                <p className="font-semibold md:text-2xl text-lg">
                                    {user.name} {user.surname}
                                </p>
                                <p className="font-thin text-sm">
                                    {user.location.city}
                                </p>
                                <p className="font-thin text-sm">
                                    Membro dal:{" "}
                                    <span className="font-normal">
                                    <Moment format="DD/MM/YYYY">
                                    {user.createdAt.toISOString()}
                                    </Moment>
                                    </span>
                                </p>
                                <p className="font-thin">
                                    Donazioni:{" "}
                                    <span className="font-normal">
                                        {regali.length}
                                    </span>
                                </p>
                                <p className="font-thin">
                                    Adozioni:{" "}
                                    <span className="font-normal">
                                        {regali.length}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 flex justify-center md:w-auto w-full mt-5 gap-2 ">
                            <div className="flex">
                                <Segnala user={user} />
                            </div>
                            <div className="flex">
                                <Recensisci user={user} />
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-3">

                    <p className="font-semibold mt-5">Recensioni</p>
                    <div className="mt-5 flex justify-start items-center space-x-1">
                        <p className="font-semibold">
                            {recensioni.length ? stars : "Nessuna recensione"}
                        </p>
                        {Array.from({ length: stars }).map((_, i) => {
                            return (
                                <div className="w-6 text-verde">
                                    <Recensione />
                                </div>
                            );
                        })}
                        <p className="font-light text-sm">({recensioni.length})</p>
                    </div>
                    </div>
                    <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-1 mt-4  gap-4">
                        {recensioni.map((item, i) => {
                            return (
                                <div key={i} className="flex gap-2">
                                    <CardRecensioni review={item} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex flex-col w-full items- mt-14">
                        <p className="text-3xl text-verdeScuro font-bold font-malik">
                            Regali
                        </p>
                        {loaded2 ? (
                            regali.length ? (
                                <div className=" grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 mt-4 gap-4 flex-grow">
                                    {regali.map((item, i) => {
                                        return (
                                            <CardPublic gift={item} key={i} />
                                        );
                                    })}
                                </div>
                            ) : (
                                <p className="mt-12">
                                    Non ha ancora pubblicato niente
                                </p>
                            )
                        ) : (
                            <Caricamento />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default Profili;
