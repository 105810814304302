import React from "react";
import BottoneRosso from "../../Atoms/Bottoni/BottoneRosso";

function Chisiamo({ firsted }) {
    return (
        <div className="md:bg-black/20 fixed  md:left-0 left-1/2 md:translate-x-0 -translate-x-1/2 md:top-0 top-28 md:mt-[-6rem] mt-[-10rem] bg-opacity-25 z-[998] w-full h-[120vh] flex items-center justify-center">
            <div className=" md:max-w-[50vw] max-w-[90vw] md:pr-0  ">
                <div className="p-8  bg-white border-rosso rounded-xl border-2 border-solid">
                    <h1 className="md:text-5xl text-4xl font-malik text-center text-verdeScuro font-bold">
                        <span className="font-bold">Benvenuto!</span>
                        <br />
                    </h1>
                    <div className="flex flex-col space-y-3 mt-5 ">
                        <p className="text-lg">
                            Da ora anche tu sei un
                            <span className="font-bold">Gifter!</span>
                            <br />
                            I Gifter sono eroi moderni che combattono lo spreco,
                            aiutano le persone e promuovono il riciclo.
                            <br />
                            Unisciti alla nostra missione. :) <br />
                            Scopri di più su di noi e inizia la tua
                            <span className="font-bold">
                                avventura sostenibile!
                            </span>
                        </p>
                    </div>
                    <div className="flex justify-center mt-10">
                        <BottoneRosso
                            onclick={firsted}
                            text={"Come funziona?"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chisiamo;
