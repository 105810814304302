import React, { useEffect, useRef, useState } from "react";
import useMeasure from "react-use-measure";
import {
    useDragControls,
    useMotionValue,
    useAnimate,
    motion,
    AnimatePresence,
} from "framer-motion";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import BottoneRosso from "../Bottoni/BottoneRosso";
import BottoneVerde2 from "../Bottoni/BottoneVerde2";
import FileUploadBtn from "../Bottoni/FileUploadBtn";
import CategorieDropdownRegalo from "../../Molecules/Dropdown/CategorieDropdownRegalo";
import StaggeredDropDownRegalo from "../../Molecules/Dropdown/StaggeredDropDownRegalo";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { uploadImage } from "../../../utils/ImageUtils";
import BottoneGrigio from "../Bottoni/BottoneGrigio";
import useUserStore from "../../../stores/userStore";

const ModRegaloPhone = ({ regalo }) => {
    const user = useUserStore((state) => state.user);
    const [open, setOpen] = useState(false);
    const [titolo, setTitolo] = useState(regalo.titolo);
    const [descrizione, setDescrizione] = useState(regalo.descrizione);
    const [imagePreview, setImagePreview] = useState(regalo.images[0]);
    const [mainImageFile, setMainImageFile] = useState(null);
    const [additionalImages, setAdditionalImages] = useState([]);
    const [additionalImageFiles, setAdditionalImageFiles] = useState([null, null, null, null]);
    const [categoria, setCategoria] = useState(regalo.microcategoria);
    const [selectedCondizioni, setSelectedCondizioni] = useState(regalo.condizioni);
    const [disponibilita, setDisponibilita] = useState(regalo.disponibilita);
    const [loading, setLoading] = useState(false);
    const [next, setNext] = useState(1);
    const [charCount, setCharCount] = useState(0);
    const [charDCount, setCharDCount] = useState(0);
    const [isValidTitolo, setIsValidTitolo] = useState(true);
    const [isValidDescrizione, setIsValidDescrizione] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(0);

    const fileButton = useRef(null);

    useEffect(() => {
        const images = regalo.images.slice(1, 5).map((img) => (img === "" ? null : img));
        while (images.length < 4) {
            images.push(null);
        }
        setAdditionalImages(images);
    }, [regalo.img]);

    const handleTitoloChange = (event) => {
        const value = event.target.value;
        setCharCount(value.length > 50 ? "Titolo troppo lungo!" : value.length);
        setTitolo(value);
        setIsValidTitolo(true);
    };

    const handleDescChange = (event) => {
        const value = event.target.value;
        setCharDCount(value.length > 700 ? "Descrizione troppo lunga!" : value.length);
        setDescrizione(value);
        setIsValidDescrizione(true);
    };

    const onPhotoChoose = (e) => {
        const file = e.target.files[0];
        setMainImageFile(file);
        const imageUrl = URL.createObjectURL(file);
        setImagePreview(imageUrl);
        setNext(1);
    };

    const onAdditionalPhotoChoose = (e, index) => {
        const file = e.target.files[0];
        const newAdditionalImages = [...additionalImages];
        newAdditionalImages[index] = URL.createObjectURL(file);

        const newAdditionalImageFiles = [...additionalImageFiles];
        newAdditionalImageFiles[index] = file;

        setAdditionalImages(newAdditionalImages);
        setAdditionalImageFiles(newAdditionalImageFiles);
    };

    const removeAdditionalPhoto = (index) => {
        const newAdditionalImages = [...additionalImages];
        newAdditionalImages[index] = null;

        const newAdditionalImageFiles = [...additionalImageFiles];
        newAdditionalImageFiles[index] = null;

        setAdditionalImages(newAdditionalImages);
        setAdditionalImageFiles(newAdditionalImageFiles);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!mainImageFile && !imagePreview)
                throw new Error("Immagine principale non definita");
            if (!titolo || !descrizione || !categoria || !user.uid || !disponibilita || !selectedCondizioni)
                throw new Error("Dati mancanti");

            const mainImage = mainImageFile ? await uploadImage(mainImageFile) : imagePreview;

            const additionalImagesPromises = additionalImageFiles.map((file, index) =>
                file ? uploadImage(file) : Promise.resolve(additionalImages[index])
            );

            const updatedAdditionalImages = await Promise.all(additionalImagesPromises);
            updatedAdditionalImages.unshift(mainImage);

            const regaloRef = doc(db, "regali", regalo.id);
            await updateDoc(regaloRef, {
                titolo,
                descrizione,
                microcategoria: categoria,
                condizioni: selectedCondizioni,
                disponibilita,
                img: updatedAdditionalImages,
                updatedAt: new Date().toISOString(),
            });

            setOpen(false);
            setModalIsOpen(true);
        } catch (error) {
            console.error("Errore durante l'aggiornamento: ", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSetIsOpen = (dropdownNumber) => {
        setDropdownIsOpen((prevIsOpen) => (prevIsOpen === dropdownNumber ? 0 : dropdownNumber));
    };

    useEffect(() => {
        const body = document.querySelector("body");
        body.style.overflow = open ? "hidden" : "auto";
    }, [open]);

    return (
        <div className="">
            <div className="h-[100%]">
                <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="bg-verdeScuro/50 px-3 py-2 text-white font-semibold rounded-lg"
                >
                    Modifica
                </button>
            </div>
            <DragCloseDrawer open={open} setOpen={setOpen}>
                <div className="flex flex-col h-full justify-between bg-white ">
                    <div className="flex flex-col justify-center items-center h-full p-2">
                        <div className={`${next > 0 ? "hidden" : "flex"} flex-col justify-center items-center`}>
                            <h2 className="font-bold text-2xl">Per iniziare, seleziona le foto</h2>
                            <h3>Inserisci delle immagini che mostrano il prodotto</h3>
                            <div className="mt-5 flex justify-center items-center">
                                <BottoneRosso
                                    text={"Carica foto"}
                                    onclick={() => fileButton.current.click()}
                                />
                                <input
                                    ref={fileButton}
                                    onChange={onPhotoChoose}
                                    type="file"
                                    accept=".jpeg,.jpg,.png"
                                    className="hidden"
                                />
                            </div>
                        </div>
                        <div className={`${next === 1 ? "block" : "hidden"} grid grid-cols-1 w-full h-full gap-4`}>
                            <div className="p-2 w-full relative rounded-lg h-full flex justify-center items-center">
                                {imagePreview && <img src={imagePreview} className="h-80" alt="" />}
                                <button
                                    onClick={() => setImagePreview(null) && setMainImageFile(null)}
                                    className={`${imagePreview ? "block" : "hidden"} absolute top-2 right-2 bg-red-600 text-white rounded-full p-1`}
                                >
                                    <IoClose className="w-5 h-5" />
                                </button>
                                <div className={`${imagePreview ? "hidden" : "flex"} justify-center items-center w-full`}>
                                    <BottoneRosso
                                        text={"Carica foto"}
                                        onclick={() => fileButton.current.click()}
                                    />
                                </div>
                                <input
                                    ref={fileButton}
                                    onChange={onPhotoChoose}
                                    type="file"
                                    className="hidden"
                                />
                            </div>
                            <div className="flex w-full items-center justify-center bg-grigio rounded-lg">
                                <div className="grid grid-cols-4 w-full h-[100%] place-content-center place-items-center gap-2">
                                    {additionalImages.map((image, index) => (
                                        <div key={index} className="flex flex-col w-full h-[100%] items-center relative">
                                            {image ? (
                                                <>
                                                    <img
                                                        src={image}
                                                        alt={`Immagine facoltativa ${index + 1}`}
                                                        className="w-full h-[100%] object-cover rounded"
                                                    />
                                                    <button
                                                        onClick={() => removeAdditionalPhoto(index)}
                                                        className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-1"
                                                    >
                                                        <IoClose className="w-5 h-5" />
                                                    </button>
                                                </>
                                            ) : (
                                                <div className="w-full h-[100%]">
                                                    <input
                                                        type="file"
                                                        onChange={(e) => onAdditionalPhotoChoose(e, index)}
                                                        className="hidden"
                                                        id={`additionalImage${index}`}
                                                    />
                                                    <label htmlFor={`additionalImage${index}`}>
                                                        <FileUploadBtn />
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={`${next === 2 ? "block custom-scrollbar" : "hidden"} flex flex-col pb-5 items-center h-full w-full overflow-y-auto`}>
                            <div className="w-full mb-3 px-2 justify-center flex">
                                <CategorieDropdownRegalo
                                    setMicro={setCategoria}
                                    micro={categoria}
                                    isOpen={dropdownIsOpen === 3}
                                    setIsOpen={handleSetIsOpen}
                                    opennth={3}
                                />
                            </div>
                            <div className="flex flex-col px-2 w-full mb-3 gap-3">
                                <div className="w-full">
                                    <label htmlFor="descr" className="text-sm">
                                        Disponibilità
                                    </label>
                                    <div className="flex w-full justify-center">
                                        <StaggeredDropDownRegalo
                                            isOpen={dropdownIsOpen === 4}
                                            setIsOpen={handleSetIsOpen}
                                            opennth={4}
                                            setChange={setDisponibilita}
                                            titolo="Disponibilità"
                                            initialValue={disponibilita}
                                            opzioni={[
                                                { text: "Infrasettimanale di giorno" },
                                                { text: "Infrasettimanale di sera" },
                                                { text: "Fine settimana" },
                                                { text: "Flessibile" },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label htmlFor="descr" className="text-sm">
                                        Condizione
                                    </label>
                                    <div className="flex w-full justify-center">
                                        <StaggeredDropDownRegalo
                                            isOpen={dropdownIsOpen === 2}
                                            setIsOpen={handleSetIsOpen}
                                            setChange={setSelectedCondizioni}
                                            opennth={2}
                                            initialValue={selectedCondizioni}
                                            titolo="Condizione"
                                            opzioni={[
                                                { text: "Come nuovo" },
                                                { text: "Buone" },
                                                { text: "Accettabili" },
                                                { text: "Da aggiustare" },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full px-2 flex flex-col">
                                <label htmlFor="titolo" className="text-sm">Titolo</label>
                                <input
                                    type="text"
                                    id="titolo"
                                    maxLength={50}
                                    value={titolo}
                                    className={`appearance-none w-full bg-background border-0 rounded-lg p-2 focus:ring-verde ${isValidTitolo ? "" : "border border-rosso"}`}
                                    onChange={handleTitoloChange}
                                />
                                <label className="place-self-end text-sm" htmlFor="titolo">{charCount}/50</label>
                            </div>
                            <div className="w-full px-2 flex flex-col">
                                <label htmlFor="descr" className="text-sm">Descrizione</label>
                                <textarea
                                    id="descr"
                                    value={descrizione}
                                    maxLength={300}
                                    className={`appearance-none h-24 w-full bg-background border-0 rounded-lg p-2 focus:ring-verde ${isValidDescrizione ? "" : "border border-rosso"}`}
                                    onChange={handleDescChange}
                                />
                                <label className="place-self-end text-sm" htmlFor="descr">{charDCount}/300</label>
                            </div>
                        </div>
                    </div>
                    <div className={`${next > 0 ? "flex" : "hidden"} items-center pb-16 justify-end gap-3 mt-1 rounded-b-lg w-full`}>
                        <button onClick={() => setNext(next - 1)} className="py-2 px-4 border border-verdeScuro rounded-lg">Indietro</button>
                        {next === 1 && <BottoneRosso text={"Avanti"} onclick={() => setNext(2)} />}
                        {next === 2 && (
                            loading ? (
                                <BottoneGrigio text={"Pubblicando..."} onclick={()=>""} />
                            ) : (
                                <BottoneRosso text={"Pubblica"} onclick={handleSubmit} />
                            )
                        )}
                    </div>
                </div>
            </DragCloseDrawer>
            <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </div>
    );
};

const Modal = ({ isOpen, setIsOpen }) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="relative pt-4 pb-8 px-4 max-w-xl bg-white flex flex-col rounded-lg overflow-hidden"
                    >
                        <div className="w-full flex justify-end">
                            <IoClose onClick={() => setIsOpen(false)} className="text-3xl text-grigio cursor-pointer" />
                        </div>
                        <div className="w-full px-4 flex flex-col gap-5">
                            <p className="text-center text-3xl font-bold">Regalo pubblicato!</p>
                            <p className="text-center">Grazie per la tua <span className="font-bold">Donazione :)</span></p>
                            <p className="text-center">Stai contribuendo a rendere il mondo un posto migliore, grazie per la tua generosità!</p>
                        </div>
                        <div className="flex justify-center w-full mt-10">
                            <BottoneGrigio text={"Continua"} onclick={() => setIsOpen(false)} />
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const DragCloseDrawer = ({ open, setOpen, children }) => {
    const [scope, animate] = useAnimate();
    const [drawerRef, { height }] = useMeasure();

    const y = useMotionValue(0);
    const controls = useDragControls();

    const handleClose = async () => {
        animate(scope.current, { opacity: [1, 0] });
        const yStart = typeof y.get() === "number" ? y.get() : 0;
        await animate("#drawer", { y: [yStart, height] });
        setOpen(false);
    };

    return (
        <>
            {open && (
                <motion.div
                    ref={scope}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    onClick={handleClose}
                    className="fixed inset-0 z-50 bg-verdeScuro/30"
                >
                    <motion.div
                        id="drawer"
                        ref={drawerRef}
                        onClick={(e) => e.stopPropagation()}
                        initial={{ y: "100%" }}
                        animate={{ y: "0%" }}
                        transition={{ ease: "easeInOut" }}
                        className="absolute bottom-0 h-[90svh] w-full overflow-hidden rounded-t-3xl bg-white"
                        style={{ y }}
                        drag="y"
                        dragControls={controls}
                        onDragEnd={() => { if (y.get() >= 100) handleClose(); }}
                        dragListener={false}
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={{ top: 0, bottom: 0.5 }}
                    >
                        <div className="absolute left-0 right-0 top-0 z-10 flex items-center justify-between bg-white p-4">
                            <div className="w-10"></div>
                            <button
                                onPointerDown={(e) => { controls.start(e); }}
                                className="h-2 w-14 hidden cursor-grab touch-none rounded-full bg-verdeScuro active:cursor-grabbing"
                            ></button>
                            <div onClick={handleClose}>
                                <IoClose className="text-3xl text-grigio cursor-pointer" />
                            </div>
                        </div>
                        <div className="relative z-0 h-full overflow-y-scroll p-4 pt-16">
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </>
    );
};

export default ModRegaloPhone;
