import React from "react";
import ProfilePic from "../ProfilePic/ProfilePic";
import ProfilePicName from "../ProfilePic/ProfilePicName";
import Recensione from "../../../assets/Icons/Recensione";

/**
 * @param {Object} props 
 * @param {import("../../../utils/API/UserAPI").Review} props.review
 * @returns 
 */
function CardRecensioni({ review }) {
    return (
        <div className="bg-white p-3  md:min-w-[15rem] md:max-w-[18rem]  w-full flex flex-col gap-2 rounded-lg shadow-md">
            <div className="flex gap-2">
                <ProfilePic userId={review.author} />
                <ProfilePicName userId={review.author} />
            </div>
            <div className="flex gap-2 text-lg items-center font-semibold">
                <p>{review.rating}.0</p>
                <div className="flex gap-1">
                    {Array.from({ length: review.rating }).map((_, i) => {
                        return (
                            <div key={i} className="w-5 text-verde">
                                <Recensione />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="text-sm font-light mt-3">
                <p>{review.content}</p>
            </div>
        </div>
    );
}

export default CardRecensioni;
