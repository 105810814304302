import React, { useEffect, useState, useCallback, useRef } from "react";
import NavLanding from "../../Components/Molecules/Sidebar/NavLanding";
import BottoneGrigio from "../../Components/Atoms/Bottoni/BottoneGrigio";
import donna from "../../assets/PNG/Landing/TGI LP donna hp@3x.png";
import donnaM from "../../assets/PNG/Landing/TGI LP donna mobile@3x.png";
import foto1 from "../../assets/PNG/Landing/TGI LP foto 1 verde@3x.png";
import foto2 from "../../assets/PNG/Landing/TGI LP foto 2 verde@3x.png";
import foto3 from "../../assets/PNG/Landing/TGI LP fot 3 verde@3x.png";
import foto1M from "../../assets/PNG/Landing/TGI LP foto 1 verde mobile@3x.png";
import foto2M from "../../assets/PNG/Landing/TGI LP foto 2 verde mobile@3x.png";
import foto3M from "../../assets/PNG/Landing/TGI LP foto 3 verde mobile@3x.png";
import phone from "../../assets/PNG/Landing/phone.png";
import Cerchio12 from "../../assets/Illustrazioni/Landing/Cerchio12";
import SfondoVerde from "../../assets/Illustrazioni/Landing/SfondoVerde";
import LOGO_WHITE from "../../assets/Logos/LOGO_WHITE";
import Mondo from "../../assets/PNG/Landing/TGI LP mondo@3x.png";
import "./Landing.css";
import Cibo from "../../assets/Illustrazioni/Landing/Cibo";
import Vestiti from "../../assets/Illustrazioni/Landing/Vestiti";
import Oggetti from "../../assets/Illustrazioni/Landing/Oggetti";
import Cerchio22 from "../../assets/Illustrazioni/Landing/Cerchio22";
import BottoneVerde2 from "../../Components/Atoms/Bottoni/BottoneRosso";
import Icon from "../../assets/Logos/Logo";
import Processo from "../../assets/PNG/Landing/TGI LP Processo Web@3x.png";
import Punti from "../../assets/Punti";
import {
	FaInstagram,
	FaUserCircle,
	FaFacebookF,
	FaTiktok,
} from "react-icons/fa";
import SfondoFooter from "../../assets/Illustrazioni/Landing/SfondoFooter";
import Cerchio1M from "../../assets/Illustrazioni/Landing/Cerchio1M";
import Cerchio2M from "../../assets/Illustrazioni/Landing/Cerchio2M";
import SfondoVerdeM from "../../assets/Illustrazioni/Landing/SfondoVerdeM";
import ProcessoM from "../../assets/PNG/Landing/TGI LP Processo Mobile@3x.png";
import SfondoFooterM from "../../assets/Illustrazioni/Landing/SfondoFooterM";
import SorrisoBianco from "../../assets/Illustrazioni/Landing/SorrisoBianco";
import LogoBianco from "../../assets/PNG/Loghi/RegaloBianco.png";
import BottoneVerde from "../../Components/Atoms/Bottoni/BottoneVerde";
import axios from "axios";
import LoadingBtn from "../../Components/Atoms/Bottoni/LoadingBtn";
import useUserStore from "../../stores/userStore";

function Facciata() {
    const logged = useUserStore((state) => state.isLogged);
    const scrollRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [mail, setMail] = useState("");
    const [nome, setNome] = useState("");
    const [messaggio, setMessaggio] = useState("");
    const [messageSent, setMessageSent] = useState(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				console.log(entry);
				if (entry.isIntersecting) {
					setIsVisible(entry.isIntersecting); // Aggiorna lo stato quando il componente è visibile
				} else if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
					setIsVisible(false);
				}
			},
			{
				threshold: 0.1, // Percentuale di visibilità per attivare il callback
			}
		);

		if (scrollRef.current) {
			observer.observe(scrollRef.current); // Inizia ad osservare il contenitore
		}

		return () => {
			if (scrollRef.current) {
				observer.unobserve(scrollRef.current); // Pulisci l'osservatore quando il componente si smonta
			}
		};
	}, []);

	const scrollToForm = () => {
		const formElement = document.getElementById("form");
		if (formElement) {
			formElement.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		} else {
			console.log("formElement not found");
		}
	};

	const handleSubmit = async () => {
		try {
			if (mail && nome && messaggio) {
				const headers = {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				};
				await axios
					.post(
						process.env.REACT_APP_API_URL + "/mail/landing",
						{
							nome: nome,
							mail: mail,
							testo: messaggio,
						},
						{ headers: headers }
					)
					.then((res) => {
						console.log(res.data);
						setMessageSent(true);
						setMail("");
						setNome("");
						setMessaggio("");
						setTimeout(() => {
							setMessageSent(null);
						}, 4000);
					});
			} else {
				setMessageSent(false);
			}
		} catch (error) {
			setMessageSent(false);
		}
	};

	return (
		<div className="bg-white  ">
			<NavLanding logged={logged} scrolled={isVisible} onclick={scrollToForm} />
			<div
				id="ciao"
				className="relative flex md:flex-row flex-col justify-center items-center w-full md:h-[70vh]  "
			>
				<div className="px-[10%] flex flex-col">
					<h1 className="md:text-7xl text-5xl font-extrabold text-verdeScuro">
						Non buttarlo,
					</h1>
					<h1 className="md:text-7xl text-5xl font-extrabold text-verde">
						Regalalo!
					</h1>
					<p className="mt-[5%] text-xl leading-normal md:block hidden text-verdeScuro">
						<span className="text-verde font-semibold">Togiftit</span> è la{" "}
						<span className="font-semibold">prima piattaforma in Italia</span>,
						pensata per{" "}
						<span className="font-semibold">
							trovare gratuitamente oggetti di ogni tipo
						</span>{" "}
						e per condividere con gli altri ciò di cui{" "}
						<span className="font-semibold">non hai più bisogno!</span>
					</p>
					<div className="w-[40%] mt-[10%] place-self- md:block hidden">
						<BottoneGrigio
							text="Scopri la WebApp"
							onclick={() => (window.location.href = logged ? "/" : "/webapp")}
						/>
					</div>
				</div>
				<div className="w-[120%] h-[120%] hidden md:block ">
					<img
						src={donna}
						alt="placeholder"
						className="w-full h-full object-cover "
					/>
				</div>
				<div className="w-[100%] h-[100%] md:hidden">
					<img
						src={donnaM}
						alt="placeholder"
						className="w-full h-full object-cover "
					/>
				</div>
				<div className="absolute top-[85%] z-20 w-full px-[10%]  ">
					<p className="mt-[5%] md:text-lg leading- md:hidden">
						<span className="text-verde font-semibold">Togiftit</span> è la{" "}
						<span className="font-semibold">prima piattaforma in Italia</span>,
						pensata per{" "}
						<span className="font-semibold">
							trovare gratuitamente oggetti di ogni tipo
						</span>{" "}
						e per condividere con gli altri ciò di cui{" "}
						<span className="font-semibold">non hai più bisogno!</span>
					</p>
					<div className="md:w-[50%] w-[55%] md:translate-y-1/2 translate-y-[70%] absolute  md:hidden">
						<BottoneGrigio
							text="Scopri la WebApp"
							onclick={() => (window.location.href = logged ? "/" : "/webapp")}
						/>
					</div>
				</div>
			</div>
			<div
				className={`relative w-full ${
					window.innerWidth > 990 && "shadow-top"
				} md:pt-[10%] pt-[70%] bg-white`}
			>
				<div className="absolute top-[5%] left-0 z-0 w-full h-auto md:block hidden">
					<Cerchio12 />
				</div>
				<div className="absolute -top-[11%] left-0 z-0 w-full h-auto md:hidden">
					<Cerchio1M />
				</div>
				<div className="w-full flex flex-col justify-center items-center gap-16">
					<div className="w-[70%] grid md:grid-cols-2 items-center justify-items-center gap-x-[5%] z-10">
						<img src={foto1} alt="foto1" className=" hidden" />
						<img src={foto1M} alt="foto1" className="" />
						<div className="justify-self-start md:mt-0 mt-[5%] text-center md:text-left ">
							<h2 className="md:text-4xl text-2xl text-verdeScuro font-bold">
								Regala... e scegliti un regalo!
							</h2>
							<p className="mt-[3%]">
								Risparmia sull’acquisto di nuovi prodotti,{" "}
								<br className="md:block hidden" /> condividi e trova ciò che ti
								fa comodo nella tua zona
							</p>
						</div>
					</div>
					<div className="w-[70%] grid md:grid-cols-2 items-center justify-items-center gap-x-[5%] z-10">
						<img src={foto2} alt="foto1" className=" hidden" />
						<img src={foto2M} alt="foto1" className="" />
						<div
							ref={scrollRef}
							className="justify-self-start md:mt-0 mt-[5%] text-center md:text-left"
						>
							<h2 className="md:text-4xl text-2xl text-verdeScuro font-bold">
								Aiuta la natura
							</h2>
							<p className="mt-[3%]">
								Incentivando il riciclo e l’economia circolare.{" "}
								<br className="md:block hidden" />
								Meno rifiuti = tanti danni risparmiati al pianeta!
							</p>
						</div>
					</div>
					<div className="w-[70%] md:grid grid-cols-2 items-center justify-items-center gap-x-[5%] z-10">
						<img src={foto3} alt="foto1" className=" hidden" />
						<img src={foto3M} alt="foto1" className="" />
						<div className="justify-self-start md:mt-0 mt-[5%] text-center md:text-left">
							<h2 className="md:text-4xl text-2xl text-verdeScuro font-bold">
								E chi sta vicino a te.
							</h2>
							<p className="mt-[3%]">
								Costruiamo una comunità donando ciò che non usiamo{" "}
								<br className="md:block hidden" />e aiutando il prossimo.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className=" mt-[5%]">
				<div className="relative z-0">
					<div className="md:hidden">
						<SfondoVerdeM />
					</div>
					<div className="md:block hidden">
						<SfondoVerde />
					</div>
					<div className="w-full md:px-0 px-4 absolute z-10 text- flex flex-col items-center justify-center gap-4 text-white top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
						<img src={LogoBianco} className="md:w-[5%] w-[15%]" alt="" />
						<div className="md:text-xl ">
							<h2 className="md:text-6xl text-4xl font-bold self-start mb-4">
								Chi siamo?
							</h2>
							<p>
								<span className="font-bold">Togiftit</span> è la piattaforma che
								ti permette di{" "}
								<span className="font-bold">donare e ricevere oggetti</span>{" "}
								riducendo gli sprechi.
							</p>
							<p>
								&Egrave; semplicissimo:{" "}
								<span className="font-bold">
									registrati, cerca l'oggetto che desideri,
								</span>{" "}
								<span className="font-bold">
									contatta il donatore <br className="hidden md:block" />
								</span>{" "}
								tramite la chat per{" "}
								<span className="font-bold">organizzare il ritiro.</span>
							</p>
							<p>
								Grazie al tuo gesto contribuirai a{" "}
								<span className="font-bold">ridurre le emissioni di CO2</span> e
								ad incentivare la <br className="hidden md:block" />{" "}
								<span className="font-bold">salvaguardia dell’ambiente!</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="relative mt-[5%] w-full flex flex-col justify-center items-center gap-[8vh] px-4 md:px-0">
				<div className="absolute top-[5%] left-0 z-0 w-full h-auto hidden md:block">
					<Cerchio22 />
				</div>
				<div className="absolute top-[15%] left-0 z-0 w-full h-auto md:hidden">
					<Cerchio2M />
				</div>
				<h2 className="z-10  text-center md:text-4xl text-3xl font-bold text-verdeScuro leading-[30px]">
					Un gesto per <span className="z-10 text-verde">il pianeta...</span>
				</h2>
				<p className="z-10 w-full md:px-0 px-4 md:text-center md:text-xl text- leading-">
					Il{" "}
					<span className="z-10 font-semibold">
						nostro continuo spreco di risorse
					</span>{" "}
					e l’
					<span className="z-10 font-semibold">
						accumulo di oggetti inutilizzati
					</span>{" "}
					<br className="md:block hidden" /> nelle nostre case (o in garage...)
					stanno{" "}
					<span className="z-10 font-semibold">
						danneggiando seriamente il pianeta,
					</span>
					<br className="md:block hidden" /> e di riflesso,{" "}
					<span className="z-10 font-semibold">anche noi stessi</span>.
				</p>
				<div className="z-10 md:w-[30%] w-[60%]">
					<img src={Mondo} alt="" />
				</div>
				<h2 className="z-10  text-center md:text-4xl text-3xl font-bold text-verdeScuro leading-[30px]">
					...e un gesto per{" "}
					<span className="z-10 text-verde">il tuo portafoglio!</span>
				</h2>
				<div className="z-10 md:w-[40%] w-[70%]">
					<Cibo />
				</div>
				<p className="z-10 w-full md:text-center md:text-xl text- leading- md:px-0 px-4">
					Il <span className="z-10 font-semibold">cibo sprecato</span>, tutti
					quei <span className="z-10 font-semibold">vestiti</span> nei nostri
					armadi che non vengono{" "}
					<span className="z-10 font-semibold">mai indossati</span>,{" "}
					<br className="md:block hidden" />
					quei
					<span className="z-10 font-semibold">
						{" "}
						libri “interessantissimi”
					</span>{" "}
					ricevuti a Natale e <br className="md:block hidden" />
					<span className="z-10 font-semibold"> mai letti</span> (giustamente,
					forse)...
				</p>
				<div className="z-10 md:w-[40%] w-[70%]">
					<Vestiti />
				</div>
				<p className="z-10 w-full md:text-center md:text-xl text- leading- font-semibold md:px-0 px-4">
					...elettrodomestici, giocattoli, strumenti digitali, da lavoro, sport
					e hobbistica, <br className="md:block hidden" />
					mobili e elementi d’arredo inutilizzati.
				</p>
				<div className="z-10 md:w-[50%] w-[70%]">
					<Oggetti />
				</div>
				<p className="z-10 w-full md:text-center md:text-xl text- leading- md:px-0 px-4">
					Inoltre, molti oggetti vengono{" "}
					<span className="z-10 font-semibold">
						acquistati solo occasionalmente
					</span>{" "}
					<br className="md:block hidden" />o per usi estremamente limitati.
				</p>
				<h2 className="z-10  text-center md:text-4xl text-3xl font-bold text-verdeScuro">
					<span className="z-10 font-bold text-verde">Condividere</span> di più,
					significa{" "}
					<span className="z-10 font-bold text-verde">
						ridurre gli sprechi!
					</span>
				</h2>
				<p className="z-10 w-full md:text-center md:text-xl text- leading- md:px-0 px-4">
					<span className="z-10 font-semibold">
						Siamo qui per incoraggiarti
					</span>{" "}
					a condividere ciò che non ti serve più,{" "}
					<br className="md:block hidden" />
					con le persone che
					<span className="z-10 font-semibold">
						{" "}
						potrebbero averne bisogno intorno a te
					</span>
					.
				</p>
			</div>
			<div className="mt-[5%] w-full flex flex-col justify-center items-center gap-[10vh] md:px-0 px-4">
				<div className="md:w-[10%] w-[40%]">
					<BottoneVerde2
						text="Iscriviti gratis"
						onclick={() => (window.location.href = "/login")}
						fullWidth={true}
					/>
				</div>
				<h2 className="md:text-4xl text-3xl font-bold text-center text-verdeScuro md:px-0 px-4 leading-[30px]">
					La missione ambiziosa <br className="md:block hidden" />{" "}
					<span className="text-verde">di regalare un sorriso.</span>
				</h2>
				<div className="md:w-[20%] w-[50%] -my-[10vh]  text-center">
					<SorrisoBianco fill="#fdfdfd" />
				</div>
				<div className="flex flex-col justify-center items-center gap-[5vh]">
					<h2 className="md:text-4xl text-3xl font-bold text-center md:px-0 px-4">
						Stiamo creando <br className="md:hidden" />{" "}
						<span className="text-verde">un ecosistema...</span>{" "}
						<br className="md:block hidden" />
						<span className="hidden md:block">
							dove aiuti l'ambiente <br className="md:hidden" />
							<span className="text-verde">e fai shopping gratuito!</span>
						</span>
					</h2>
					<div className="w-[70%] md:hidden">
						<img src={ProcessoM} alt="" />
					</div>
					<h2 className="md:text-4xl text-3xl font-bold text-center md:px-0 px-4 leading-[30px] md:hidden">
						dove aiuti l'ambiente <br className="md:hidden" />
						<span className="text-verde">e fai shopping gratuito!</span>
					</h2>
				</div>
				<div className="w-[40%] hidden md:block">
					<img src={Processo} alt="" />
				</div>
			</div>
			<div className=" w-full pt-[10%] bg-white text-verdeScuro">
				<div className="w-full flex flex-col justify-center items-center gap-16">
					<div className="w-[70%] grid md:grid-cols-2 items-center justify-items-center gap-[5%] z-10">
						<div className="justify-self-start flex flex-col  ">
							<h2 className="md:text-4xl text-3xl font-bold  leading-[30px]">
								<span className="text-verde">Carica</span>{" "}
								<br className="md:hidden" /> un oggetto
							</h2>
							<p className="mt-[3%] ">
								...non uno di quelli che ti serve, qualcosa di cui ti devi
								sbarazzare <br className="md:block hidden" />o che non utilizzi,{" "}
								<span className="font-bold">mi raccomando! ;)</span>
							</p>
							<p className="mt-[3%] ">
								Accumulerai <span className="font-bold">100</span>{" "}
								<span className="text-rosso font-bold">Punti Gift</span>{" "}
								<span className="font-bold">
									{"("}
									<span className="inline-block align-middle w-6">
										<Punti />
									</span>
									{"),"}
								</span>{" "}
								la nostra{" "}
								<span className="font-bold">“moneta” della generosità</span>,
								per <span className="span font-bold">ogni regalo</span> che
								farai all’interno della piattaforma, ed{" "}
								<span className="font-bold">altri punti</span> per le azioni
								virtuose che svolgerai nei confronti dell’ambiente e della
								comunità! I{" "}
								<span className="text-rosso font-bold">Punti Gift</span>{" "}
								<span className="font-bold">non sono acquistabili</span>, si
								guadagnano con la generosità e la partecipazione!
							</p>
							<div className="md:self-start  self-center w-[30%] mt-[3%] hidden md:block">
								<BottoneVerde2
									text="Condividi"
									onclick={() =>
										(window.location.href = logged ? "/" : "/webapp")
									}
									fullWidth={true}
								/>
							</div>
						</div>
						<div className="mt-[10%] h-full relative flex flex-col gap-[5%] items-center justify-center ">
							<div className="absolute w-[70%] md:block hidden aspect-square rounded-full bg-[#f5f3f6] z-0"></div>
							<div className="absolute w-[70%] md:hidden top-1/4 -translate-y-1/4 aspect-square rounded-full bg-[#f5f3f6] z-0"></div>
							<div className="relative w-[40%] z-10">
								<img src={phone} alt="" className="relative z-20" />
								<img
									src={require("../../assets/PNG/Landing/1.jpg")}
									className="absolute md:px-3 px-1 z-10 md:rounded-3xl rounded-lg md:top-3 top-1"
									alt=""
								/>
							</div>
							<div className="md:self-end md:w-full self-center w-[55%] mt-[3%] md:hidden">
								<BottoneVerde2
									text="Condividi"
									onclick={() => () =>
										(window.location.href = logged ? "/" : "/webapp")}
									fullWidth={true}
								/>
							</div>
						</div>
					</div>
					<div className="w-[70%] grid md:grid-cols-2 items-center justify-items-center  gap-x-[5%] z-10">
						<div className="justify-self-start flex flex-col ">
							<h2 className="md:text-4xl text-3xl font-bold  leading-[30px]">
								<span className="text-verde">Prenotati </span>{" "}
								<br className="md:hidden" /> per un regalo
							</h2>

							<p className="mt-[3%] ">
								Si, qualcosa che ti farebbe comodo! Sfrutta la ricerca avanzata
								per <span className="font-bold">categorie</span> e{" "}
								<span className="font-bold">distanza</span> da te. Ti verranno
								sottratti <span className="font-bold">100</span>{" "}
								<span className="text-rosso font-bold">Punti Gift</span>{" "}
								<span className="font-bold">
									{"("}
									<span className="inline-block align-middle w-6">
										<Punti />
									</span>
									{")"}
								</span>{" "}
								<span className="font-bold">
									alla prenotazione, questi punti andranno al donatore se sarai
									tu a ricevere il regalo{" "}
								</span>
								mentre ti verranno <span className="font-bold">restituiti</span>{" "}
								in caso il regalo scelto venisse assegnato a qualcun’altro.
							</p>

							<div className="md:self-start  self-center w-[30%] mt-[3%] md:block hidden">
								<BottoneVerde2
									text="Cerca"
									onclick={() =>
										(window.location.href = logged ? "/" : "/webapp")
									}
									fullWidth={true}
								/>
							</div>
						</div>
						<div className="mt-[10%] h-full relative flex flex-col gap-[5%] items-center justify-center ">
							<div className="absolute w-[70%] md:block hidden aspect-square rounded-full bg-[#f5f3f6] z-0"></div>
							<div className="absolute w-[70%] md:hidden top-1/4 -translate-y-1/4 aspect-square rounded-full bg-[#f5f3f6] z-0"></div>
							<div className="relative w-[40%] z-10">
								<img src={phone} alt="" className="relative z-20" />
								<img
									src={require("../../assets/PNG/Landing/2.jpg")}
									className="absolute md:px-3 px-1 z-10 md:rounded-3xl rounded-lg md:top-3 top-1"
									alt=""
								/>
							</div>
							<div className="md:self-end md:w-full self-center w-[55%] mt-[3%] md:hidden">
								<BottoneVerde2
									text="Cerca"
									onclick={() =>
										(window.location.href = logged ? "/" : "/webapp")
									}
									fullWidth={true}
								/>
							</div>
						</div>
					</div>
					<div className="w-[70%] grid md:grid-cols-2 items-center justify-items-center gap-x-[5%] z-10">
						<div className="justify-self-start flex flex-col  ">
							<h2 className="md:text-4xl text-3xl font-bold  leading-[30px]">
								<span className="text-verde">Adesso</span>{" "}
								<br className="md:hidden" /> tocca a te!
							</h2>

							<p className="mt-[3%] ">
								Stiamo cercando di creare{" "}
								<span className="font-bold">l’ambiente giusto</span> per voi, lo
								scopo dei{" "}
								<span className="text-rosso font-bold">Punti Gift</span>{" "}
								<span className="font-bold">
									{"("}
									<span className="inline-block align-middle w-6">
										<Punti />
									</span>
									{")"}
								</span>
								è infatti limitare pratiche scorrette e opportunistiche
								all'interno della piattaforma (come quella di prenotarsi a
								tutto). <br /> Vi sarà inoltre il{" "}
								<span className="font-bold">limite mensile di 10 regali</span>,
								per assicurare una maggiore equità possibile.
							</p>

							<div className="md:self-start  self-center w-[30%] mt-[3%] md:block hidden">
								<BottoneVerde2
									text="Iscriviti"
									onclick={() =>
										(window.location.href = logged ? "/" : "/login")
									}
									fullWidth={true}
								/>
							</div>
						</div>
						<div className="mt-[10%] h-full relative flex flex-col gap-[5%] items-center justify-center ">
							<div className="absolute w-[70%] md:block hidden aspect-square rounded-full bg-[#f5f3f6] z-0"></div>
							<div className="absolute w-[70%] md:hidden top-1/4 -translate-y-1/4 aspect-square rounded-full bg-[#f5f3f6] z-0"></div>
							<div className="relative w-[40%] z-10">
								<img src={phone} alt="" className="relative z-20" />
								<img
									src={require("../../assets/PNG/Landing/3.jpg")}
									className="absolute md:px-3 px-1 z-10 md:rounded-3xl rounded-lg md:top-3 top-1"
									alt=""
								/>
							</div>
							<div className="md:self-end md:w-full self-center w-[55%] mt-[3%] md:hidden">
								<BottoneVerde2
									text="Iscriviti"
									onclick={() =>
										(window.location.href = logged ? "/" : "/login")
									}
									fullWidth={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				id="form"
				className="w-full flex md:flex-row flex-col justify-evenly items-center gap-[10vh] md:mt-[5%] mt-[40%]	"
			>
				<div className="flex flex-col text-center justify-center items-center md:px-0 px-4">
					<h3 className="font-bold text-3xl leading-[30px]">
						Ti va di fare due parole?
					</h3>
					<h3 className="font-bold text-3xl text-verde  leading-[30px]">
						Beh, anche a noi, scrivici!
					</h3>
					<div className="grid grid-cols-2 w-full gap-2 mt-[8%]	">
						<input
							value={nome}
							onChange={(e) => setNome(e.currentTarget.value)}
							type="text"
							className="appearance-none border-2 border-grigio focus:ring-0 focus:border-grigio px-2 rounded-lg"
							placeholder="Nome"
						/>{" "}
						<input
							value={mail}
							onChange={(e) => setMail(e.currentTarget.value)}
							type="mail"
							className="appearance-none border-2 border-grigio px-2 rounded-lg"
							placeholder="Email"
						/>
						<textarea
							value={messaggio}
							onChange={(e) => setMessaggio(e.currentTarget.value)}
							className="h-44 col-span-2 border-2 border-grigio appearance-none focus:ring-0 focus:border-grigio px-2 rounded-lg"
							placeholder="Scrivi qui il tuo messaggio..."
						></textarea>
						<div className=" col-span-2 w-full">
							<LoadingBtn
								onClick={handleSubmit}
								success={messageSent}
								type="submit"
								fullWidth
							>
								Invia
							</LoadingBtn>
						</div>
					</div>
				</div>
				<div className="flex flex-col justify-center items-center gap-2">
					<h3 className="font-bold text-3xl text-center text-verdeScuro leading-[30px]">
						Seguici per restare aggiornato...
					</h3>
					<h3 className="font-bold text-3xl text-verde text-center leading-[30px]">
						Non te ne pentirai!
					</h3>
					<div className="flex justify-center items-center gap-5 mt-8">
						<button
							onClick={() =>
								window.open(
									"https://www.facebook.com/profile.php?id=100091961577076"
								)
							}
							className="bg-verdeScuro md:w-10 md:h-10 w-14 h-14 aspect-square rounded-full flex justify-center items-center"
						>
							<FaFacebookF className="md:w-[60%] md:h-[60%] w-[65%] h-[65%] text-white" />
						</button>
						<button
							onClick={() => window.open("https://instagram.com/togiftit/")}
							className="bg-verdeScuro md:w-10 md:h-10 w-14 h-14 aspect-square rounded-full flex justify-center items-center"
						>
							<FaInstagram className="md:w-[60%] md:h-[60%] w-[65%] h-[65%] text-white" />
						</button>
						<button
							onClick={() => window.open("https://tiktok.com/@togiftit")}
							className="bg-verdeScuro md:w-10 md:h-10 w-14 h-14 aspect-square rounded-full flex justify-center items-center"
						>
							<FaTiktok className="md:w-[60%] md:h-[60%] w-[65%] h-[65%] text-white" />
						</button>
					</div>
				</div>
			</div>
			<div className="w-full md:h-[70vh] h-[30vh] flex justify-center gap-5 mt-[15%] relative">
				<div className="w-full absolute z-0 bottom-0 hidden md:block">
					<SfondoFooter fill={"#f5f3f6"} />
				</div>
				<div className="w-full absolute z-0 bottom-0 md:hidden ">
					<SfondoFooterM fill={"#f5f3f6"} />
				</div>
				<div className="w-full h-full z-10 flex flex-col items-center md:mt-[5%] mt-[30%] gap-10">
					<h3 className="font-extrabold text-verdeScuro text-center md:text-3xl text-xl z-10 md:px-0 px-4 leading-[30px]">
						Regala un sorriso,{" "}
						<span className="text-verde">a chi ti sta intorno.</span>
					</h3>
					<button
						onClick={() => (window.location.href = logged ? "/" : "/webapp")}
						className="md:w-[20%] w-[50%] md:h-[15%] rounded-full text-white md:text-2xl text-xl p-2 font-semibold bg-verdeScuro z-10"
					>
						Scopri la <span className="font-bold">WebApp</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Facciata;
