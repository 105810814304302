import { create } from "zustand";
import { persist } from "zustand/middleware";


/**
 * @typedef {Object} User
 * @property {string} _id
 * @property {string} uid
 * @property {string} email
 * @property {string} name
 * @property {string} surname
 * @property {GiftLocation|null} location
 * @property {number} points
 * @property {number} level
 * @property {string} image
 * @property {number} donations
 * @property {number} adoptions
 * @property {boolean} isFirstLogin
 * @property {boolean} hasNotifications
 * @property {string|null} invitedBy 
 * @property {Date} createdAt
 */

const useUserStore = create(
    persist(
        (set) => ({
            user: null,
            isLogged: false,
            setUser: (value) => set({ user: value }),
            setIsLogged: (value) => set({ isLogged: value }),
            cambiaLocalita: async (value) => {
                // async al backend
            }
})));

export default useUserStore;