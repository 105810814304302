import React, { useState } from "react";
import {
    FiMenu,
    FiArrowRight,
    FiX,
    FiChevronDown,
    FiSearch,
} from "react-icons/fi";
import { FaHamburger, FaUserCircle } from "react-icons/fa";
import { LuHeartHandshake } from "react-icons/lu";
import { PiChatsCircle } from "react-icons/pi";
import {
    useMotionValueEvent,
    AnimatePresence,
    useScroll,
    motion,
} from "framer-motion";
import useMeasure from "react-use-measure";
import Logo from "../../../assets/Logos/Logo";
import Pittogramma from "../../../assets/Logos/Pittogramma";
import { FaPlus } from "react-icons/fa6";
import { IoMenu } from "react-icons/io5";
import useUserStore from "../../../stores/userStore";

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);
    const { scrollY } = useScroll();
    const logged = useUserStore((state) => state.isLogged);
    const user = useUserStore((state) => state.user);

    useMotionValueEvent(scrollY, "change", (latest) => {
        setScrolled(latest > 250 ? true : false);
    });

    return (
        <nav
            className={`fixed h-[5rem] top-0 z-50 w-full px-6 text-verdeScuro bg-white transition-all duration-300 ease-out lg:px-6 bg-neutral-950 py-3 `}
        >
            <div className="mx-auto h-full flex items-center justify-between">
                <div
                    onClick={() => (window.location.href = "/")}
                    className="md:w-36 w-56"
                >
                    <span className="md:block hidden">
                        <Logo />
                    </span>
                    {logged ? (
                        <div className="flex gap-5 items-center text-2xl font-semibold md:hidden fill-verde">
                            <span className="w-7">
                                {" "}
                                <Pittogramma />
                            </span>
                            <p className="md:hidden block">Ciao {user.name}</p>
                        </div>
                    ) : (
                        <span className="block md:hidden">
                            <Logo />
                        </span>
                    )}
                </div>
                {logged && (
                    <div className="hidden gap-6 lg:flex ">
                        <Links />
                    </div>
                )}
                <div className="hidden gap-6 lg:flex ">
                    <CTAs user={user} />
                </div>
                <MobileMenu />
            </div>
        </nav>
    );
};

const Links = () => {
    return (
        <div className="flex items-center gap-6">
            {LINKS.map((l) => (
                <NavLink
                    key={l.text}
                    href={l.href}
                    FlyoutContent={l.component}
                    icon={l.icon}
                >
                    {l.text}
                </NavLink>
            ))}
        </div>
    );
};

const NavLink = ({ children, href, FlyoutContent, icon }) => {
    const [open, setOpen] = useState(false);

    const showFlyout = FlyoutContent && open;

    return (
        <div
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            className="relative h-fit w-fit"
        >
            <a href={href} className="relative flex items-center gap-2">
                <span className="text-3xl">{icon}</span>
                {children}
            </a>
        </div>
    );
};

const CTAs = ({ user }) => {
    return (
        <div className="flex items-center gap-3">
            <button className="rounded-lg flex gap-2 items-center border-2 border-verdeScuro bg-verdeScuro px-4 py-2 font-semibold text-white transition-colors ">
                <FaPlus />
                <p> Fai un regalo</p>
            </button>
            <MenuButton FlyoutContent={MenuContent}>
                <div className="flex items-center gap-1 border  border-grigio p-2 rounded-lg">
                    <IoMenu className="text-2xl" />
                    {user && user.image ? (
                        <img src={user.image} alt="" />
                    ) : (
                        <span className="w-5 fill-grigio">
                            <Pittogramma />
                        </span>
                    )}
                </div>
            </MenuButton>
        </div>
    );
};

const MenuButton = ({ children, href, FlyoutContent, icon }) => {
    const [open, setOpen] = useState(false);

    const showFlyout = FlyoutContent && open;

    return (
        <div
            onClick={() => setOpen((pv) => !pv)}
            className="relative h-fit w-fit cursor-pointer"
        >
            <a href={href} className="relative">
                {children}
            </a>
            <AnimatePresence>
                {showFlyout && (
                    <motion.div
                        initial={{ opacity: 0, y: 15 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 15 }}
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        className="absolute right-0 top-16 bg-white text-black"
                    >
                        <FlyoutContent />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const MenuContent = () => {
    return (
        <div className=" h-fit ">
            <div className="flex min-w-52 flex-col gap-1 p-1  shadow-lg">
                {MENU.map((m) => (
                    <a
                        key={m.text}
                        href={m.href}
                        className="text-lg hover:bg-grigino px-3 py-2 rounded-lg"
                    >
                        {m.text}
                    </a>
                ))}
            </div>
        </div>
    );
};

const MobileMenuLink = ({ children, href, FoldContent, setMenuOpen }) => {
    const [ref, { height }] = useMeasure();
    const [open, setOpen] = useState(false);

    return (
        <div className="relative text-neutral-950">
            {FoldContent ? (
                <div
                    className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
                    onClick={() => setOpen((pv) => !pv)}
                >
                    <a
                        onClick={(e) => {
                            e.stopPropagation();
                            setMenuOpen(false);
                        }}
                        href={href}
                    >
                        {children}
                    </a>
                    <motion.div
                        animate={{ rotate: open ? "180deg" : "0deg" }}
                        transition={{
                            duration: 0.3,
                            ease: "easeOut",
                        }}
                    >
                        <FiChevronDown />
                    </motion.div>
                </div>
            ) : (
                <a
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(false);
                    }}
                    href="#"
                    className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
                >
                    <span>{children}</span>
                    <FiArrowRight />
                </a>
            )}
            {FoldContent && (
                <motion.div
                    initial={false}
                    animate={{
                        height: open ? height : "0px",
                        marginBottom: open ? "24px" : "0px",
                        marginTop: open ? "12px" : "0px",
                    }}
                    className="overflow-hidden"
                >
                    <div ref={ref}>
                        <FoldContent />
                    </div>
                </motion.div>
            )}
        </div>
    );
};

const MobileMenu = () => {
    const [open, setOpen] = useState(false);
    return (
        <div className="block lg:hidden">
            <button onClick={() => setOpen(true)} className="block text-3xl">
                <FiMenu />
            </button>
            <AnimatePresence>
                {open && (
                    <motion.nav
                        initial={{ x: "100vw" }}
                        animate={{ x: 0 }}
                        exit={{ x: "100vw" }}
                        transition={{ duration: 0.15, ease: "easeOut" }}
                        className="fixed left-0 top-0 flex h-screen w-full flex-col bg-white"
                    >
                        <div className="flex items-center justify-between p-6">
                            <div className="w-36">
                                <Logo />
                            </div>
                            <button onClick={() => setOpen(false)}>
                                <FiX className="text-3xl text-neutral-950" />
                            </button>
                        </div>
                        <div className="h-screen overflow-y-scroll bg-neutral-100 p-6">
                            {LINKS.map((l) => (
                                <MobileMenuLink
                                    key={l.text}
                                    href={l.href}
                                    FoldContent={l.component}
                                    setMenuOpen={setOpen}
                                >
                                    {l.text}
                                </MobileMenuLink>
                            ))}
                        </div>
                        <div className="flex justify-end bg-neutral-950 p-6">
                            <CTAs />
                        </div>
                    </motion.nav>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Navbar;

const LINKS = [
    {
        text: "Esplora",
        icon: <FiSearch />,
        href: "/",
    },
    {
        text: "Trattative",
        icon: <LuHeartHandshake />,
        href: "/transactions",
    },
    {
        text: "Messaggi",
        icon: <PiChatsCircle />,
        href: "/chat",
    },
];

const MENU = [
    {
        text: "Accedi",
        href: "/login",
    },
    {
        text: "Notifiche",
        href: "/notifications",
    },
    {
        text: "Invita amici",
        href: "/invite",
    },
    { text: "I tuoi crediti", href: "/credits" },
];
