import React, { useEffect, useState } from "react";
import Profilo from "../../../assets/Profilo";
import { lvl } from "../../../Pages/Profilo/lvl";
import UserAPI from "../../../utils/API/UserAPI";
import useUserStore from "../../../stores/userStore";

/**
 * @param {Object} props
 * @param {string} props.userId
 * @param {boolean} [props.full]
 * @param {number} [props.w]
 * @returns
 */

/**
 * @type {[import("../../../utils/API/UserAPI").User, function]}
 */

function ProfilePic({ full = false, w = 8, userId }) {
    const [user, setUser] = [
        useUserStore((state) => state.user),
        useUserStore((state) => state.setUser),
    ];
    const [loaded, setLoaded] = useState(false);
    const getUser = async () => {
        try {
            const user = await UserAPI.getById(userId);
            setUser(user);

            setTimeout(() => {
                setLoaded(true);
            }, 200);
        } catch (error) {}
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            {loaded && user ? (
                <div
                    className={`relative z-0 inline-flex ${
                        full ? "bg-rosso" : "bg-transparent"
                    } items-center justify-center w-${w} aspect-square`}
                >
                    <div className="relative w-full h-full overflow-hidden border rounded-full">
                        {user.image ? (
                            <img
                                className="object-cover w-full h-full"
                                src={user.image}
                                alt=""
                            />
                        ) : (
                            <Profilo />
                        )}
                    </div>
                    <div
                        id="lvl"
                        className="absolute w-[40%] z-10 -right-[5%] -bottom-[5%]"
                    >
                        {user.level !== undefined &&
                            lvl[user.level] &&
                            lvl[user.level].img()}
                    </div>
                </div>
            ) : (
                //TODO: Fix placeholder
                <div
                    className={`w-${w} h-${w} bg-grigio bg-opacity-20 rounded-full`}
                />
            )}
        </>
    );
}

export default ProfilePic;
