import React from "react";

function BottoneVerde({ text, onclick }) {
	return (
		
			<button
				type="button"
				onClick={() => onclick()}
				className="py-2 px-6 text-center hover:text-rosso text-white border border-rosso hover:bg-rosso/30 bg-rosso focus:ring-4 focus:outline-none focus:ring-rosso/30 font-semibold rounded-lg text-lg  flex items-center justify-center"
			>
				{text}
			</button>
	
	);
}

export default BottoneVerde;
