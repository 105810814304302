import React, { useEffect, useState } from "react";
import Punti from "../../../assets/Punti";
import { AsyncImage } from "loadable-image";
import { calcolaDistanza } from "../../../utils/calcolaDistanza";
import { ToggleFavourite } from "../Bottoni/ToggleButtons";
import UserAPI from "../../../utils/API/UserAPI";
import { CiLocationOn } from "react-icons/ci";
import IconaAccettabile from "../../../assets/Icons/Stati/Accettabile";
import IconaBuono from "../../../assets/Icons/Stati/Buono";
import IconaComeNuovo from "../../../assets/Icons/Stati/ComeNuovo";
import IconaDaAggiustare from "../../../assets/Icons/Stati/DaAggiustare";
import { giftStatus } from "../../../utils/API/GiftAPI";

import useUserStore from "../../../stores/userStore";
/**
 *
 * @param {Object} props
 * @param {import("../../../utils/API/UserAPI").User} props.user
 * @param {import("../../../utils/API/GiftAPI").Gift} props.gift
 * @returns
 */
function CardPublic({ gift, chiave = 0, dash = false }) {
    const img = gift.images.length
        ? gift.images[0].length
            ? gift.images[0]
            : gift.images[1].length
                ? gift.images[1]
                : gift.images[2].length
                    ? gift.images[2]
                    : gift.images[3].length
                        ? gift.images[3]
                        : gift.images[4].length
                            ? gift.images[4]
                            : gift.images[5]
                                ? gift.images[5]
                                : require("../../../assets/default/default-image.jpg")
        : require("../../../assets/default/default-image.jpg");

    const user = useUserStore((state) => state.user);
	const logged = useUserStore((state) => state.logged);
    const [Distanza, setDistanza] = useState(0);
    const [isLiked, setLiked] = useState(false);
    const [owner, setOwner] = useState("");

	const handleFavourite = async () => {
		if (isLiked) {
			await UserAPI.removeFavourite(gift._id);
			setLiked(false);
		} else {
			await UserAPI.addFavourite(gift._id);
			setLiked(true);
		}
	};

	const getUser = async () => {
		try {
			const owner = await UserAPI.getById(gift.owner);
			setOwner(owner.name);
			if (user) {
				setDistanza(
					await calcolaDistanza(
						user.location.lat,
						user.location.lng,
						owner.location.lat,
						owner.location.lng
					)
				);
			}
		} catch (error) {}
	};

	const fetchLiked = async () => {
		if (!user) {
			return false;
		}

		const favourites = await UserAPI.getFavourites();
		setLiked(favourites.includes(gift._id));
	};

	useEffect(() => {
		getUser();
		logged && fetchLiked();
	}, [chiave]);

	const Condizioni = ({ condizioni }) => {
		return (
			<p className="flex md:text-sm space-x-0 items-center">
				<span className="text-verdeScuro mr-1 w-4">
					{condizioni === "acceptable" ? (
						<IconaAccettabile />
					) : condizioni === "good" ? (
						<IconaBuono />
					) : condizioni === "new" ? (
						<IconaComeNuovo />
					) : (
						<IconaDaAggiustare />
					)}
				</span>
				<nobr>{condizioni ? giftStatus[condizioni] : "Stato Mancante"}{" "}</nobr>
			</p>
		);
	};

	return (
		<div>
			<div className="md:max-w-[14rem] relative transition-all duration-200 hover:scale-[98%]">
				<div className="absolute z-10 top-0 right-0   w-10 aspect-square flex justify-center items-center">
					{logged && <ToggleFavourite isSelected={isLiked} onClick={handleFavourite} />}
				</div>
				<div className=" cursor-pointer flex justify-start items-center absolute top-2 left-2 z-10 bg-background shadow-lg rounded-lg">
					<div
						onClick={() =>
							(window.location.href = !dash
								? "/prodotto/" + gift._id
								: "/dashboard/" + gift._id)
						}
						className="flex items-center justify-between"
					>
						<span className="px-2 flex items-center  justify-center space-x-1 md:font-bold font-semibold text-black ">
							<p>100</p>
							<div className="w-4">
								<Punti />
							</div>
						</span>
					</div>
				</div>

				<div
					className="flex relative items-center justify-center"
					onClick={() =>
						(window.location.href = !dash
							? "/prodotto/" + gift._id
							: "/dashboard/" + gift._id)
					}
				>
					<AsyncImage
						className="w-full aspect-square object-cover rounded-lg cursor-pointer"
						src={img}
						alt={gift.name}
					/>
					<div className="absolute flex space-x-2 left-2 bottom-2">
						{gift.bookingsLength ? (
							<div className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-verde">
								<p>
									{gift.bookingsLength} Prenotat
									{gift.bookingsLength === 1 ? "o" : "i"}
								</p>
							</div>
						) : (
							<div className="absolute"></div>
						)}
						{
							//TODO: Add single "finalized" field
							gift.isDelivered ? (
								<div className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-rosso">
									<p>Regalato</p>
								</div>
							) : gift.isAssigned ? (
								<div className=" md:px-2 px-1 py-1 text-xs rounded-lg text-white bg-verdeScuro">
									<p>Assegnato</p>
								</div>
							) : (
								<div className="absolute"></div>
							)
						}
					</div>
				</div>
				<div className="flex py-2 flex flex-col md:mt-0 mt-1">
					<h5
						onClick={() =>
							(window.location.href = !dash
								? "/prodotto/" + gift._id
								: "/dashboard/" + gift._id)
						}
						className="text-md cursor-pointer md:block hidden font-semibold tracking-tight text-black "
					>
						{gift.name.slice(0, 25)}
						{gift.name.length > 25 && "..."}
					</h5>
					<h5
						onClick={() =>
							(window.location.href = !dash
								? "/prodotto/" + gift._id
								: "/dashboard/" + gift._id)
						}
						className="text-md cursor-pointer pl-1 block md:hidden font-medium tracking-tight text-black "
					>
						{gift.name.slice(0, 16)}
						{gift.name.length > 16 && "..."}
					</h5>
					<div className="flex md:mt-1 md:space-x-5 space-x-3 md:text-sm text-xs items-center md:pl-0 pl-0">
						{/*owner*/}
						<div className="flex items-center space-x-0">
							<CiLocationOn className="text-base" />
							<span className="md:text-sm text-xs">{gift.location.city}</span>
						</div>
						<div className="flex items-center space-x-0">
							<div className="flex justify-center items-center ">
								<Condizioni condizioni={gift.status} />
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
}

export default CardPublic;
